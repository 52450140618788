import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PageWrapper from "../components/layout/PageWrapper"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <PageWrapper>
      <Wrapper>
        <Content>
          <h1>404: Page Not Found</h1>
          <p>
            Sorry, you just entered the page that doesn't exist.
            <br /> Please go to the Fire Skilled homepage.
          </p>
          <Link to="/" className="btn">
            Go to homepage
          </Link>
        </Content>
      </Wrapper>
    </PageWrapper>
  </Layout>
)

export default NotFoundPage

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 5rem - 9rem - 6rem);
  /* TODO: fix flex */
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 1rem;
    text-align: center;
  }
`
